import React, { ReactNode, RefObject } from "react";
import { Button, ButtonGroup, Col, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import * as Icons from "react-bootstrap-icons";
import styled from "styled-components";
import EntityTypeahead from "./EntityTypeahead";
import { diffAndPostAssociations, getAssociations } from "../../utils/Requests";
import { getCustomerConfig } from "../../config/Customers";
import EntityAssociationModal from "./EntityAssociationModal";
import { AlertTopRight } from "../SharedStyled";

const MessageContainer = styled.div`
    text-align: center;
`;

const EntitiesTabs = styled(Tabs)`
    margin: 0;
`;

const HiddenTabs = styled(Tabs)`
    display: none;
`;

const ButtonsRow = styled(Row)`
    margin: 0;
`;

const SpinnerDiv = styled.div`
    position: absolute;
    top: 39%;
    right: 50%;
    z-index: 10;
`;

type ItemEntityViewState = {
    loading: boolean,
    refresh: boolean,
    error: boolean,
    entityAssociations: EntityAssociation[],
    additionalEntities: EntityAssociation[],
    errorMsg?: string,
    enabled: boolean,
    showSuccess?: boolean,
    showError?: boolean,
    showAssociationModal?: boolean
}

type ItemEntityViewProperties = {
    seedId: string,
    customer: string,
    thing: Record<string, any>,
    origin: string,
    onTabSelect: (selectedTab: string) => void;
}

export default class ItemEntityView extends React.Component<ItemEntityViewProperties, ItemEntityViewState> {

    private autoFranchiseEntityTypeahead: RefObject<EntityTypeahead>;
    private franchiseEntityTypeahead: RefObject<EntityTypeahead>;
    private genreEntityTypeahead: RefObject<EntityTypeahead>;
    private peopleEntityTypeahead: RefObject<EntityTypeahead>;
    private tagEntityTypeahead: RefObject<EntityTypeahead>;
    private moodEntityTypeahead: RefObject<EntityTypeahead>;
    private otherEntityTypeahead: RefObject<EntityTypeahead>;

    constructor(props: ItemEntityViewProperties) {
        super(props);

        this.autoFranchiseEntityTypeahead = React.createRef<EntityTypeahead>();
        this.franchiseEntityTypeahead = React.createRef<EntityTypeahead>();
        this.genreEntityTypeahead = React.createRef<EntityTypeahead>();
        this.peopleEntityTypeahead = React.createRef<EntityTypeahead>();
        this.tagEntityTypeahead = React.createRef<EntityTypeahead>();
        this.moodEntityTypeahead = React.createRef<EntityTypeahead>();
        this.otherEntityTypeahead = React.createRef<EntityTypeahead>();

        this.state = {
            loading: true,
            refresh: false,
            error: false,
            entityAssociations: new Array<EntityAssociation>(),
            additionalEntities: new Array<EntityAssociation>(),
            enabled: false,
            showSuccess: false,
            showError: false,
            showAssociationModal: false
        };
    }

    public componentDidMount(): void {
        this.getEntityAssociations();
    }

    public render(): ReactNode {
        if (this.state.loading) {
            return <MessageContainer>Loading...</MessageContainer>;
        } else if (this.state.error) {
            return (
                <MessageContainer>
                    <div>Error occurred loading associated entities : </div>
                    <div>{this.state.errorMsg}</div>
                </MessageContainer>
            );
        }

        return this.createEntityTabs();
    }

    private toggleTypeahead = (): void => {
        this.setState({
            enabled: !this.state.enabled
        });
    }

    private closeSuccess = (): void => {
        this.setState({
            showSuccess: false
        });
    }

    private closeError = (): void => {
        this.setState({
            showError: false
        });
    }

    private saveAssociations = async (): Promise<void> => {
        this.setState({
            refresh: true,
            enabled: false
        });

        let allAssociations: EntityAssociation[] = [];
        if (this.franchiseEntityTypeahead.current) {
            allAssociations = allAssociations.concat(this.franchiseEntityTypeahead.current.getAssociations());
        }
        if (this.genreEntityTypeahead.current) {
            allAssociations = allAssociations.concat(this.genreEntityTypeahead.current.getAssociations());
        }
        if (this.peopleEntityTypeahead.current) {
            allAssociations = allAssociations.concat(this.peopleEntityTypeahead.current.getAssociations());
        }
        if (this.tagEntityTypeahead.current) {
            allAssociations = allAssociations.concat(this.tagEntityTypeahead.current.getAssociations());
        }
        if (this.moodEntityTypeahead.current) {
            allAssociations = allAssociations.concat(this.moodEntityTypeahead.current.getAssociations());
        }
        if (this.otherEntityTypeahead.current) {
            allAssociations = allAssociations.concat(this.otherEntityTypeahead.current.getAssociations());
        }

        const postResponse = await diffAndPostAssociations(
            getCustomerConfig(this.props.customer),
            this.props.thing,
            this.state.entityAssociations,
            allAssociations,
            this.props.origin,
            false
        );

        if (postResponse.ok) {
            this.setState({
                showSuccess: true
            }, this.startRefresh);
        } else {
            this.setState({
                showError: true,
                refresh: false
            });
        }
    }

    private displayAssociationModal = (show: boolean) => {
        this.setState({
            showAssociationModal: show
        });
    }

    private handleTabSelect = (selectedTab: string) => {
        this.props.onTabSelect(selectedTab);
    };

    private createEntityTabs(): ReactNode {

        const autofranchises = new Array<EntityAssociation>();
        const franchises = new Array<EntityAssociation>();
        const genres = new Array<EntityAssociation>();
        const people = new Array<EntityAssociation>();
        const tags = new Array<EntityAssociation>();
        const moods = new Array<EntityAssociation>();
        const other = new Array<EntityAssociation>();

        for (const association of this.state.entityAssociations) {

            switch (association.entityType.toLowerCase()) {
                case "autofranchise":
                    autofranchises.push(association);
                    break;
                case "franchise":
                    franchises.push(association);
                    break;
                case "genre":
                    genres.push(association);
                    break;
                case "people":
                    people.push(association);
                    break;
                case "tag":
                    tags.push(association);
                    break;
                case "mood":
                    moods.push(association);
                    break;
                default:
                    // Handle entities not controlled by this screen
                    other.push(association);
                    break;
            }
        }

        // Used to default the EntitiesTab to the entity type with the greatest number of items in it
        const entitiesSizes: { [key: string]: number } = { franchises: franchises.length, genres: genres.length, people: people.length, tags: tags.length, moods: moods.length };

        let maxKey = Object.keys(entitiesSizes).reduce((a, b) => entitiesSizes[a] > entitiesSizes[b] ? a : b);
        if (Object.values(entitiesSizes).every(item => item === 0)) {
            // If all lengths are zero, default to first tab
            maxKey = Object.keys(entitiesSizes)[0];
        }

        return (
            <>
                {this.state.refresh &&
                    <SpinnerDiv>
                        <Spinner animation="border" style={{ color: "white" }} />
                    </SpinnerDiv>}

                <EntityAssociationModal
                    show={this.state.showAssociationModal}
                    customer={this.props.customer}
                    item={this.props.seedId}
                    closeCallback={() => { this.displayAssociationModal(false); }}
                />

                <AlertTopRight
                    variant='success'
                    show={this.state.showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Associations saved
                </AlertTopRight>
                <AlertTopRight
                    variant='danger'
                    show={this.state.showError}
                    onClose={this.closeError}
                    dismissible
                >
                    Error saving associations
                </AlertTopRight>
                <div style={{ opacity: this.state.refresh ? 0.5 : 1 }}>


                    <EntitiesTabs defaultActiveKey={maxKey} onSelect={this.handleTabSelect}>
                        {/* <Tab key={`${"autofranchises"}`} eventKey={`${"autofranchises"}`} title={`${"Auto Franchises (" + autofranchises.length + ")"}`}>
                        <EntityTypeahead
                            ref={this.autoFranchiseEntityTypeahead}
                            customer={this.props.customer}
                            entityType="autofranchise"
                            existingEntityAssociations={autofranchises}
                            enabled={false}
                        />
                    </Tab> */}
                        <Tab key={`${"franchises"}`} eventKey={`${"franchises"}`} title={`${"Franchises (" + franchises.length + ")"}`}>
                            <EntityTypeahead
                                ref={this.franchiseEntityTypeahead}
                                customer={this.props.customer}
                                entityType="franchise"
                                existingEntityAssociations={franchises}
                                enabled={this.state.enabled}
                            />
                        </Tab>
                        <Tab key={`${"genres"}`} eventKey={`${"genres"}`} title={`${"Genres (" + genres.length + ")"}`}>
                            <EntityTypeahead
                                ref={this.genreEntityTypeahead}
                                customer={this.props.customer}
                                entityType="genre"
                                existingEntityAssociations={genres}
                                enabled={this.state.enabled}
                            />
                        </Tab>
                        <Tab key={`${"people"}`} eventKey={`${"people"}`} title={`${"People (" + people.length + ")"}`}>
                            <EntityTypeahead
                                ref={this.peopleEntityTypeahead}
                                customer={this.props.customer}
                                entityType="people"
                                existingEntityAssociations={people}
                                enabled={this.state.enabled}
                            />
                        </Tab>
                        <Tab key={"tags"} eventKey={"tags"} title={"Tags (" + tags.length + ")"}>
                            <EntityTypeahead
                                ref={this.tagEntityTypeahead}
                                customer={this.props.customer}
                                entityType="tag"
                                existingEntityAssociations={tags}
                                enabled={this.state.enabled}
                            />
                        </Tab>
                        <Tab key={"moods"} eventKey={"moods"} title={"Moods (" + moods.length + ")"}>
                            <EntityTypeahead
                                ref={this.moodEntityTypeahead}
                                customer={this.props.customer}
                                entityType="mood"
                                existingEntityAssociations={moods}
                                enabled={this.state.enabled}
                            />
                        </Tab>
                    </EntitiesTabs>
                    {/* This section is hidden, as users can't modify these association here, but we need to keep these associations */}
                    <HiddenTabs>
                        <Tab className={"d-none"} key={"other"} eventKey={"other"} title={"Other (" + other.length + ")"} disabled={true}>
                            <EntityTypeahead
                                ref={this.otherEntityTypeahead}
                                customer={this.props.customer}
                                entityType="other"
                                existingEntityAssociations={other}
                                enabled={false}
                            />
                        </Tab>
                    </HiddenTabs>
                    <ButtonsRow>
                        <Col md={7} style={{ paddingLeft: 0 }} />
                        <Col md={{ offset: 3, span: 2 }} style={{ paddingRight: 0 }} className="d-flex justify-content-end">
                            <ButtonGroup>
                                <Button title="Edit associations" variant="secondary" onClick={this.toggleTypeahead}>
                                    <Icons.PencilFill />
                                </Button>
                                <Button title="Save associations" variant="secondary" disabled={!this.state.enabled} onClick={this.saveAssociations}>
                                    <Icons.FileEarmarkArrowDownFill />
                                </Button>
                                <Button title="Edit association weights" variant="secondary" onClick={() => { this.displayAssociationModal(true); }}>
                                    <Icons.Gear />
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </ButtonsRow>
                </div>
            </>
        );
    }

    private startRefresh(): void {
        setTimeout(() => {
            this.refreshEntityAssociations();
        }, 1000);
        setTimeout(() => {
            this.setState({
                showSuccess: false
            });
        }, 2500);
    }

    private async refreshEntityAssociations(): Promise<void> {
        this.setState({
            refresh: true
        });

        await getAssociations(getCustomerConfig(this.props.customer), this.props.seedId).then(response => {
            return response.json();
        }).then(jsonOutput => {
            if (Array.isArray(jsonOutput)) {
                this.setState({
                    entityAssociations: jsonOutput,
                    refresh: false,
                    error: false,
                    errorMsg: ""
                });
            } else {
                this.setState({
                    entityAssociations: jsonOutput,
                    refresh: false,
                    error: true,
                    errorMsg: "Could not retrieve entity associations!"
                });
            }
        }).catch(error => {
            this.setState({
                refresh: false,
                error: true,
                errorMsg: error.message
            });
        });
    }

    private async getEntityAssociations(): Promise<void> {
        this.setState({
            loading: true
        });

        await getAssociations(getCustomerConfig(this.props.customer), this.props.seedId).then(response => {
            return response.json();
        }).then(jsonOutput => {
            if (Array.isArray(jsonOutput)) {
                this.setState({
                    entityAssociations: jsonOutput,
                    loading: false,
                    error: false,
                    errorMsg: ""
                });
            } else {
                this.setState({
                    entityAssociations: jsonOutput,
                    loading: false,
                    error: true,
                    errorMsg: "Could not retrieve entity associations!"
                });
            }
        }).catch(error => {
            this.setState({
                loading: false,
                error: true,
                errorMsg: error.message
            });
        });
    }
}